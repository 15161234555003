@mixin highlightLinks {
  color: Red !important;
  border-bottom: 2px solid red;
  text-decoration: none;
}

.INDhighlightLinks {
  .page-footer {
    .special-offers-drawer {
      .drawer-formatter__trigger {
        @include highlightLinks;
      }
    }
  }
  .back-to-top {
    @include highlightLinks;
  }
  .page-utilities__cart {
    .cart-confirm {
      .cart-confirm__content {
        a.cart-confirm__image-container {
          border-bottom: none !important;
        }
        .cart-confirm__header {
          @include highlightLinks;
        }
      }
    }
  }
  .page-main {
    .search-page {
      .search-page__filters-breadcrumbs {
        .results-clear {
          color: red;
        }
      }
    }
  }
  #cboxContent {
    #cboxClose {
      @include highlightLinks;
    }
  }
  .page-utilities {
    .page-utilities__cart-icon,
    .page-utilities__search-icon {
      @include highlightLinks;
      font-weight: bold;
    }
    .page-utilities__signin-text {
      @include highlightLinks;
      font-weight: bold;
      line-height: 20px;
    }
  }
  .doors-results__pager-pages {
    color: Red;
    font-weight: bold;
  }
  @media #{$device-ipad_only} {
    .page-navigation__menu_text {
      border-bottom: 2px solid red !important;
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  .spp {
    .product-full {
      .js-shade-picker {
        .swatch {
          background: transparent !important;
        }
        .swatch:hover {
          background-color: LemonChiffon !important;
        }
      }
    }
    .product-full__image-container {
      .slick-active {
        background: transparent;
      }
    }
  }
  .search-page {
    .js-shade-picker {
      .swatch {
        background: transparent !important;
      }
      .swatch:hover {
        background-color: LemonChiffon !important;
      }
    }
  }
  .product_brief__description {
    ul.js-shade-picker {
      a.swatch--selected {
        background: transparent !important;
      }
      a.swatch--selected:hover {
        background-color: LemonChiffon !important;
      }
    }
  }
  .back-to-top:hover {
    background-color: lemonchiffon !important;
  }
  .main-content {
    .field-elc-mpp-content {
      .js-mpp-wrapper {
        .mpp__product-grid {
          .product_brief {
            .product_brief__description {
              .product_brief__sub-panel-close:hover {
                background-color: lemonchiffon !important;
              }
            }
          }
        }
        #mpp__filter-tab {
          .tab-label:hover {
            background-color: LemonChiffon !important;
          }
        }
      }
    }
  }
  .drawer-formatter__content {
    .special-offers-tray {
      .special-offers-tray__col {
        .special-offer-benefits__content:hover,
        .gift-wrap__content:hover {
          background-color: lemonchiffon !important;
        }
      }
    }
  }
  .page-wrapper {
    #wrapper {
      #innerwrapper {
        #ultimate-diamond_container {
          .video_container {
            .video_wrapper {
              #video_section_1,
              .controls-mask {
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }
  .local-search__map-container {
    .local-search__map {
      div:not(img) {
        background-color: transparent !important;
      }
      div:nth-child(4) {
        div:nth-child(4) {
          div:nth-child(1) {
            div:nth-child(1) {
              div {
                background-color: grey !important;
              }
              div:nth-child(2) {
                div {
                  background-color: grey !important;
                }
              }
              div:nth-child(1) {
                div {
                  background-color: grey !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer-social-links {
    .youtube {
      background: url(/sites/esteelauder/themes/estee_base/img/sprites/youtube.png);
    }
  }
  .node-65192 {
    .hero-block__headline--right {
      background: transparent !important;
      div,
      h1 {
        background: transparent !important;
      }
    }
    .hero-block__promo--right {
      background: transparent !important;
      div {
        background: transparent !important;
      }
    }
  }
  .node-61910 {
    .hero-block__promo--left {
      background: transparent !important;
      div {
        background: transparent !important;
      }
    }
  }
}

.INDblackwhite {
  #sign-in-component__FIRST_NAME,
  #sign-in-component__PC_EMAIL_ADDRESS,
  #sign-in-component__PASSWORD {
    border-color: #dbdbdb;
  }
  .product_brief__description {
    .product_brief__sub-panel-close {
      background-color: white !important;
    }
  }
  .store-locator--local-search {
    .store-locator__sub-page {
      .local-search__search-container {
        .local-search__search-container-inner {
          .local-search__options {
            .local-search__option {
              color: Gold !important;
            }
          }
        }
      }
    }
  }
  .recommended-products-panel {
    input[type='submit'] {
      outline: 1px dashed Gold;
    }
    .input[type='submit']:hover {
      background-color: LemonChiffon !important;
      color: black !important;
    }
  }
  .samples-panel__content {
    .add-button input[type='submit'] {
      outline: 1px dashed Gold;
      margin: 0 0 1px 1px;
    }
    .return-link {
      margin: 0 1px 0 0;
    }
    .add-button input[type='submit']:hover {
      background-color: LemonChiffon !important;
      color: black !important;
    }
  }
  .sample-products {
    .sample-select-button {
      margin: 0 1px 0 0;
    }
  }
  .product-full__add-button {
    outline: 1px dashed Gold;
  }
  .product-full__add-button:hover {
    background-color: LemonChiffon !important;
    color: black !important;
  }
  #sign-in-panel {
    .form-submit {
      outline: 1px dashed Gold;
      margin: 0 1px 0 0;
    }
    .form-submit:hover {
      background-color: LemonChiffon !important;
      color: black !important;
    }
  }
  .page-utilities__cart-icon {
    background-image: url(/media/boutiques/anr-night-experience/assets/bag-white.png) !important;
  }
  .page-utilities__cart-icon:hover {
    background-color: LemonChiffon !important;
  }
  .page-utilities__search-icon {
    background-image: url(/media/boutiques/anr-night-experience/assets/search-white.png) !important;
  }
  .page-utilities__search-icon:hover {
    background-color: LemonChiffon !important;
  }
  .page-branding__logo {
    background: url(/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-small-alt.png) !important;
  }
  .page-branding__logo:hover {
    background-color: LemonChiffon !important;
  }
  .social-share__link {
    background-color: white !important;
  }
  .page-utilities {
    .page-utilities__cart {
      .page-utilities__cart-button {
        padding: 0 10px !important;
      }
    }
  }
  #cboxContent {
    .sign-in-component {
      .form-submit {
        border: solid 1px white !important;
      }
      .form-submit:hover {
        background-color: LemonChiffon !important;
        color: black !important;
      }
    }
  }
  #cboxLoadedContent {
    .email_popover {
      .email_popover__social_icons {
        top: 312px !important;
      }
    }
  }
  .registration-page__content {
    .registration-page__title_container {
      .title_container {
        input[type='radio'] ~ label::after {
          background: gold;
        }
      }
    }
  }
  .search-page {
    .search-submit {
      background-image: url(/sites/esteelauder/themes/estee_base/img/sprites/search_white_large.png) !important;
      background-repeat: inherit !important;
    }
  }
  input[type='submit'],
  .form-submit {
    outline: 1px dashed Gold;
    margin: 2px !important;
  }
  input[type='submit']:hover {
    background-color: LemonChiffon !important;
    color: black !important;
  }
  .special-offers-drawer,
  .page-utilities__search-icon,
  .page-utilities__cart-icon {
    outline: 1px dashed Gold;
  }
  .page-utilities__signin-text {
    outline: 1px dashed Gold;
    line-height: 20px;
  }
  ul.menu {
    .mobile_hidden {
      outline: 1px dashed Gold;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .text-form-text,
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'],
  textarea,
  .form-text {
    background-color: white !important;
    color: black !important;
  }
  .is-alternate {
    .page-navigation__menu-icon {
      background: url(/sites/esteelauder/themes/estee_base/img/sprites/cbox_close.png) no-repeat !important;
    }
  }
  #home-formatter__scroller {
    background: transparent !important;
    .home-scrollto.block-active {
      background-color: white !important;
    }
  }
}

.INDwhiteblack {
  #cboxContent {
    .sign-in-component {
      .form-submit {
        border: solid 1px black !important;
      }
      .form-submit:hover {
        background-color: LemonChiffon !important;
      }
    }
  }
  input[type='submit'],
  .form-submit {
    outline: 1px dashed MidnightBlue;
    margin: 2px !important;
  }
  input[type='submit']:hover {
    background-color: LemonChiffon !important;
  }
  .special-offers-drawer,
  .page-utilities__search-icon,
  .page-utilities__cart-icon {
    outline: 1px dashed MidnightBlue;
  }
  .page-utilities__signin-text {
    outline: 1px dashed MidnightBlue;
    line-height: 20px;
  }
  ul.menu {
    .mobile_hidden {
      outline: 1px dashed MidnightBlue;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .product-full__add-button {
    outline: 1px dashed MidnightBlue;
  }
  .product-full__add-button:hover {
    background-color: LemonChiffon !important;
  }
  @media #{$device-ipad_only} {
    .node-65192 {
      .hero-block__container:nth-child(2) {
        .hero-block__headline--right {
          background: transparent !important;
          .headline--medium:nth-child(1) {
            background: transparent !important;
            .headline--medium {
              background: white !important;
              right: -444px !important;
            }
          }
        }
        .hero-block__promo--right {
          right: 100% !important;
          width: 30% !important;
          top: 42% !important;
        }
      }
    }
    .node-61910 {
      .hero-block__container--a {
        .hero-block__promo--left {
          left: 100% !important;
          top: 48% !important;
          width: 62% !important;
          .text-promo__subhead,
          .text-promo__subcopy {
            p {
              left: -710px !important;
            }
          }
        }
      }
    }
  }
  #home-formatter__scroller {
    background: transparent !important;
    .home-scrollto.block-active {
      background-color: black !important;
    }
  }
}

.INDblackcursor {
  article.faq .faq__question,
  input[type='file'],
  #profile-pic-img img.profile-pic__img,
  .store-locator .local-search__option,
  #cboxOverlay {
    cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
  }
  #colorbox {
    cursor: url(/media/export/cms/accessibility/bigcursorblack.cur), auto !important;
  }
  .footer-signup-email {
    .email-signup {
      .email-signup__section {
        .email_signup__terms {
          input[type='checkbox'] ~ .label {
            cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
          }
        }
      }
    }
  }
  .footer-signup-sms {
    .sms-signup {
      .sms-signup__section {
        .sms-signup__terms {
          input[type='checkbox'] ~ .label {
            cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
          }
        }
      }
    }
  }
  .back-to-top-show {
    cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
  }
  .main-content {
    .field-elc-mpp-content {
      .js-mpp-wrapper {
        #mpp__filter-tab {
          .tab-label,
          .plus {
            cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
          }
        }
      }
    }
  }
  .main-inner {
    .main-content {
      .js-mpp-wrapper {
        .zz-middle-grid {
          .js-product-brief {
            .product_brief__description {
              .product_brief__sub-panel-close {
                cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
              }
            }
          }
        }
      }
    }
  }
  .drawer-formatter__content {
    .special-offers-tray {
      .special-offer-benefits,
      .special-offer,
      .gift-wrap {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
      .email_signup__terms {
        .label {
          cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
        }
      }
    }
  }
  .page-sticky-footer__inner {
    .content {
      .drawer-formatter {
        .drawer-formatter__trigger {
          cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
        }
      }
    }
  }
  .field-content {
    .store-locator {
      .store-locator__sub-pages {
        .local-search__action-link {
          cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
        }
      }
    }
  }
  .page-navigation {
    .depth-1 {
      .menu {
        h3 {
          cursor: url(/media/export/cms/accessibility/bigcursorblack.cur), auto !important;
        }
      }
    }
  }
}

.INDwhitecursor {
  article.faq .faq__question,
  input[type='file'],
  #profile-pic-img img.profile-pic__img,
  .store-locator .local-search__option,
  #cboxOverlay {
    cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
  }
  #colorbox {
    cursor: url(/media/export/cms/accessibility/bigcursorwhite.cur), auto !important;
  }
  .footer-signup-email {
    .email-signup {
      .email-signup__section {
        .email_signup__terms {
          input[type='checkbox'] ~ .label {
            cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
          }
        }
      }
    }
  }
  .footer-signup-sms {
    .sms-signup {
      .sms-signup__section {
        .sms-signup__terms {
          input[type='checkbox'] ~ .label {
            cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
          }
        }
      }
    }
  }
  .main-inner {
    .main-content {
      .js-mpp-wrapper {
        .zz-middle-grid {
          .js-product-brief {
            .product_brief__description {
              .product_brief__sub-panel-close {
                cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
              }
            }
          }
        }
      }
    }
  }
  .back-to-top-show {
    cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
  }
  .main-content {
    .field-elc-mpp-content {
      .js-mpp-wrapper {
        #mpp__filter-tab {
          .tab-label,
          .plus {
            cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
          }
        }
      }
    }
  }
  .drawer-formatter__content {
    .special-offers-tray {
      .special-offer-benefits,
      .special-offer,
      .gift-wrap {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
      .email_signup__terms {
        .label {
          cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
        }
      }
    }
  }
  .page-sticky-footer__inner {
    .content {
      .drawer-formatter {
        .drawer-formatter__trigger {
          cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
        }
      }
    }
  }
  .field-content {
    .store-locator {
      .store-locator__sub-pages {
        .local-search__action-link {
          cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
        }
      }
    }
  }
  .page-navigation {
    .depth-1 {
      .menu {
        h3 {
          cursor: url(/media/export/cms/accessibility/bigcursorwhite.cur), auto !important;
        }
      }
    }
  }
}

.INDfontsize14,
.INDfontsize15 {
  .samples-page {
    .product {
      height: 405px !important;
    }
  }
}

.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .hero-block__container {
    .hero-block__promo-copy {
      .hero-block__cta {
        margin-top: auto !important;
      }
    }
  }
  .node-61910 {
    .hero-block__promo--left {
      top: 45% !important;
    }
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .hero-block-wrapper {
    .hero-block__container--b {
      .hero-block__headline--left {
        left: auto !important;
        top: 15% !important;
      }
    }
  }
  .welcome15-overlay.alt_layout1#colorbox {
    height: 500px !important;
  }
  .welcome15-overlay.alt_layout1 {
    #cboxWrapper {
      height: 465px !important;
      #cboxContent {
        height: 465px !important;
        #cboxLoadedContent {
          height: 465px !important;
        }
      }
    }
    .email_popover {
      .email_popover__content {
        width: 495px !important;
      }
      p {
        margin: 10px 0 !important;
      }
      .email_popover__social_icons {
        top: 350px !important;
      }
      .email_input {
        input[type='submit'] {
          float: right;
        }
      }
    }
  }
}

.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .mpp__product-grid {
    .product_brief {
      .product_brief__buttons-container {
        .product_brief__desc1 {
          letter-spacing: 0.025em;
        }
      }
    }
  }
  .hero-block-wrapper {
    .hero-block__container {
      .hero-block__headline--right {
        bottom: auto !important;
      }
      .hero-block__promo--right {
        top: 64% !important;
      }
    }
  }
  .node-65192 {
    .hero-block-wrapper {
      .hero-block__container {
        .hero-block__promo--right {
          top: 50% !important;
        }
      }
    }
  }
  .footer-legal {
    li.last {
      p {
        margin: 0;
      }
    }
  }
}

.INDfontsize15 {
  .product_brief {
    .product_brief__headers {
      .product_brief__misc-flag {
        height: 6px !important;
      }
      .product_brief__header {
        font-size: 30px !important;
      }
      .product_brief__sub-header {
        font-size: 22px !important;
      }
    }
    .product_brief__panel {
      .product_brief__discount-price {
        margin-top: 7px !important;
      }
    }
  }
  #wrapper {
    #recommended-products-panel {
      #works-well-with-wrapper {
        .sku-brief-editorial {
          height: 502px !important;
        }
      }
    }
  }
  .welcome15-overlay.alt_layout1 {
    .email_popover {
      h4 {
        font-size: 73.5px !important;
      }
    }
  }
}

.INDfontsize11 {
  .welcome15-overlay.alt_layout1 {
    .email_popover {
      h4 {
        font-size: 67.5px !important;
      }
    }
  }
}

.INDfontsize12 {
  .welcome15-overlay.alt_layout1 {
    .email_popover {
      h4 {
        font-size: 69px !important;
      }
    }
  }
}

.INDfontsize13 {
  .welcome15-overlay.alt_layout1 {
    .email_popover {
      h4 {
        font-size: 70.5px !important;
      }
    }
  }
}

.INDfontsize14 {
  .welcome15-overlay.alt_layout1 {
    .email_popover {
      h4 {
        font-size: 72px !important;
      }
    }
  }
}

.INDmonochrome {
  .sample-select-button.selected {
    color: white !important;
  }
}

@media only screen and (max-width: 768px) {
  .hero-block__headline {
    .header__headline {
      right: -440px !important;
      font-size: 100px !important;
      line-height: 90px;
    }
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    top: 82px;
    svg {
      height: 35px;
    }
  }
}
