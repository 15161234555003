.page-sticky-footer {
  .beauty-chat-drawer {
    .drawer-formatter__trigger {
      background-position: 67px -795px;
      &.active {
        background-position: 67px -839px;
      }
    }
  }
}

.boutique-tout-button.plus-sign {
  padding-#{$ldirection}: 40px;
  text-align: center;
}

.product-hero-tout,
.tout__product-copy,
.cta-button-wrapper,
.button--plus-sign,
.button-expand,
.hero-block__boutique-link,
.boutique-tout-button {
  background-position: 102px -3276px;
}

.cs-quick-info__block {
  &--store-locator {
    .cs-quick-info__title {
      background-position: 45px -1838px;
    }
  }
  &--live-chat {
    .cs-quick-info__title {
      background-position: 54px -1786px;
    }
  }
  &--email-us {
    .cs-quick-info__title {
      background-position: 97px -1888px;
    }
  }
  &--call-us {
    .cs-quick-info__title {
      background-position: 80px -1938px;
    }
  }
}

.customer-service {
  .sidebar-page__sidebar {
    .level-1 {
      background-position: -452px -4444px;
    }
    .active-trail {
      .level-1 {
        background-position: -452px -4324px;
      }
    }
  }
}

.page-sticky-footer {
  .drawer-formatter__content {
    .special-offer {
      .special-offer__img {
        float: $ldirection;
        margin-#{$rdirection}: 20px;
      }
      .special-offer__text {
        margin-bottom: 10px;
        span {
          text-align: $ldirection !important;
        }
      }
      .button {
        position: static;
      }
    }
  }
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      &.special-offers-elc-nodeblock {
        border-#{$ldirection}: none;
      }
    }
  }
}

.back-to-top {
  width: 78px;
  word-wrap: break-word;
}

.section-store-locator {
  &.device-pc {
    .store-locator {
      .local-search-form {
        .local-search-form__city {
          float: $ldirection;
        }
      }
    }
  }
}

.section-foundation-finder {
  .ff-quiz--edit {
    .ff-quiz__inner {
      .ff-quiz__nav-item {
        &.active {
          .ff-quiz__nav-value {
            background-position: 0px;
          }
        }
      }
      .ff-quiz__nav-value {
        background-position: 0px;
      }
    }
  }
  .ff-quiz__carousel-wrapper {
    .noUi-base {
      .noUi-handle {
        position: absolute;
      }
    }
  }
  .ff-results {
    .ff-results {
      &__matches-sub-headline,
      &__regimen-steps {
        display: none;
      }
      &__product {
        .product_brief__image-container,
        .product_brief__image,
        .sku-brief-editorial__image {
          @media only screen and (orientation: landscape) {
            width: 100%;
          }
        }
      }
    }
  }
}

.page-wrapper {
  .el-search-block {
    padding-top: 40px;
    .el-search-block__input-container {
      margin-bottom: 20px;
      .form-text {
        font-family: $akzidenz;
      }
    }
    .el-search-block__product-results {
      margin: 10px 0px 0px 0px;
      padding-bottom: 10px;
      .product-result {
        .product-result__info {
          min-height: 111px;
          margin-top: 10px;
          .product-result__button {
            .button--dark-secondary {
              line-height: 31px;
            }
          }
        }
      }
    }
  }
  #innerwrapper {
    #ultimate-diamond_container {
      .video_container {
        &.open {
          z-index: 999;
        }
      }
    }
  }
  .product__inventory-status {
    .product__inv-status-item {
      color: $color-red;
    }
  }
}

.shade-select {
  .swatch {
    &__container {
      float: $rdirection;
    }
  }
  .selectBox-label {
    direction: ltr;
    padding-left: 0;
  }
}

.shade-select-selectBox-dropdown-menu {
  direction: ltr;
  .swatches--single.swatch {
    &__container {
      float: $rdirection;
    }
  }
}

#colorbox.colorbox__quickshop,
#product_brief__quickshop__container {
  .quickshop {
    .quickshop__view-full {
      color: $color-white;
    }
    .quickshop__price-size {
      .quickshop__size-text {
        font-weight: normal;
        color: #808080;
        span {
          margin-left: 10px;
        }
      }
      .product_brief__discount-price {
        font-weight: bold;
        span {
          margin-left: 10px;
        }
      }
    }
    .selectBox-dropdown {
      .selectBox-label {
        display: block;
      }
    }
  }
  .quickshop__price-size-select {
    width: auto;
  }
  .gift {
    .quickshop__description {
      float: left;
    }
  }
}

.page-main {
  .slick-carousel,
  .slick-slider {
    .slick-prev {
      #{$rdirection}: -10px;
      #{$ldirection}: auto;
      background-position: 10px -8575px;
    }
    .slick-next {
      #{$rdirection}: 32px;
      #{$ldirection}: auto;
      background-position: 5px -8795px;
    }
  }
  .mpp {
    margin-top: 30px;
    .product_brief__quantity-container {
      @media #{$medium-down} {
        margin-right: 7px;
      }
    }
    .product_brief {
      &__price,
      &__price-size {
        .brand-renutriv & {
          color: $color-white;
        }
        font-weight: bold;
        color: $color-black;
        font-size: 20px;
        span {
          margin-#{$ldirection}: 10px;
        }
      }
      &__ppu-price {
        .brand-renutriv & {
          color: $color-white;
        }
        font-size: 10px;
        color: $color-black;
      }
      &__discount-price {
        font-weight: bold;
        span {
          margin-#{$ldirection}: 10px;
        }
      }
    }
  }
  .spp {
    .product-full {
      &__price-text {
        font-weight: normal;
        color: #808080;
        .price-content {
          margin-left: 10px;
        }
      }
    }
    .product_brief__discount-price {
      font-weight: bold;
      .price-content {
        margin-left: 10px;
      }
    }
    .product_brief__ppu-price {
      margin-top: 10px;
    }
  }
  .product-full {
    &__price-size-select {
      width: auto;
    }
    .selectBox-dropdown {
      .selectBox-label {
        display: block;
      }
    }
  }
  .ee-landing__filters__side__wrapper.expanded {
    .ee-landing__filters__side {
      #{$ldirection}: auto;
      #{$rdirection}: 50%;
      .filter-trigger-close {
        a.ee-landing__trigger-close {
          background-position: -400px -5882px;
        }
      }
    }
  }
  .ee-landing__filters__side__wrapper.collapsed {
    .ee-landing__filters__side {
      .filter-trigger {
        a.ee-landing__trigger-open::after {
          background-position: 0px -5833px;
        }
      }
    }
  }
  .ee-carousel-formatter {
    .ee-slick-carousel__logo.logo-scale {
      top: 82px;
    }
  }
  .sppmg,
  .dwcc-carousel {
    &__carouselContainer {
      @include swap_direction(direction, ltr);
    }
    &__slidePrice {
      @include swap_direction(direction, rtl);
    }
  }
  .dwcc-carousel__v2 {
    .dwcc-slide {
      &__before {
        left: 51.3%;
      }
      &__after {
        left: 1%;
      }
    }
  }
}

.ecommerce {
  &.section-store-locator {
    .store-locator {
      .local-search-form {
        .local-search-form__city {
          width: 100% !important;
        }
        .local-search-form__zip-wrapper {
          display: none;
        }
      }
    }
  }
}

#tips_boutique {
  .step.image-right {
    .step-text {
      margin-#{$ldirection}: 6%;
      text-align: left;
    }
  }
}

#rn_container.boutique {
  div.side_nav {
    li {
      left: 0;
    }
    max-width: 350px;
  }
  #renutriv_section-6 {
    .content {
      h3 {
        span {
          margin-left: 0;
          text-align: center;
          display: block;
        }
      }
      max-width: 470px;
      .li5 {
        width: auto;
      }
      @media #{$device-ipad_only} {
        @media #{$portrait} {
          height: 70% !important;
          .header {
            #{$ldirection}: 40%;
          }
        }
        @media #{$landscape} {
          height: 40% !important;
          .header {
            #{$ldirection}: 55%;
          }
        }
      }
    }
  }
  #renutriv_section-1 {
    .content {
      @media #{$device-ipad_only} {
        max-width: 370px;
        top: 25% !important;
      }
    }
  }
  #renutriv_section-4 {
    #renutriv_section-4_li1 {
      .content {
        @media #{$device-ipad_only} {
          #{$ldirection}: 10%;
          @media #{$portrait} {
            top: 20% !important;
          }
        }
      }
      .content .experience_now img {
        top: 215px !important;
        left: auto;
        right: 0;
      }
    }
    #renutriv_section-4_li2 .content {
      .experience_now img {
        right: 0;
        margin-top: 14px;
      }
    }
    #renutriv_section-4_li3 .content,
    #renutriv_section-4_li4 .content,
    #renutriv_section-4_li5 .content {
      .experience_now img {
        right: 0;
      }
    }
    li {
      .content {
        @media #{$device-ipad_only} {
          @media #{$portrait} {
            min-width: 37%;
          }
        }
      }
    }
    #renutriv_section-4_li3 .content,
    #renutriv_section-4_li4 .content {
      @media #{$device-ipad_only} {
        @media #{$landscape} {
          top: 40% !important;
        }
      }
    }
    #renutriv_section-4_li5 {
      .content {
        @media #{$device-ipad_only} {
          #{$ldirection}: 60%;
          top: 20% !important;
          @media #{$portrait} {
            height: 50% !important;
          }
        }
      }
    }
  }
  @media #{$medium-only} {
    min-width: 100%;
    #renutriv_section-4 {
      #renutriv_section-4_li1 .content {
        max-width: 450px;
      }
      #renutriv_section-4_li2 .content {
        left: 17% !important;
        .experience_now img {
          top: 171px !important;
          left: auto;
          right: 0;
        }
      }
      #renutriv_section-4_li3 .content,
      #renutriv_section-4_li4 .content {
        max-width: 450px;
        top: 33.3333% !important;
        .experience_now img {
          top: 171px !important;
          left: auto;
          right: 0;
        }
      }
    }
    #renutriv_section-6 .content {
      max-width: 768px !important;
    }
    h3 {
      font-size: 36px !important;
      line-height: inherit !important;
    }
    a,
    p {
      font-size: 16px !important;
      line-height: inherit !important;
    }
    .renutriv_section {
      min-width: 768px;
    }
  }
}

#ultimate-diamond_container {
  #ultimate_section-3 {
    #section-3-li1 {
      div.content {
        white-space: pre-line;
        right: 55%;
      }
    }
    #section-3-li3 {
      div.content {
        white-space: pre-line;
        right: 50%;
      }
    }
  }
  .video_container {
    .controls {
      .scrubber {
        direction: ltr;
      }
    }
  }
}

.spp {
  .spp-product__anchor {
    .spp-product__anchor-tab {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.section-envy-lustre {
  .envy-lustre__frame {
    .envy-lustre__parallax-copy {
      #{$ldirection}: auto;
      #envy-lustre__parallax-copy-1 {
        margin-#{$ldirection}: 7.4%;
      }
      #envy-lustre__parallax-copy-2 {
        margin-#{$ldirection}: 6.4%;
      }
      #envy-lustre__parallax-copy-3 {
        margin-#{$ldirection}: 7.4%;
      }
      #envy-lustre__parallax-copy-4 {
        margin-#{$ldirection}: 111.8%;
      }
      #envy-lustre__parallax-copy-5 {
        width: 25%;
        margin-#{$ldirection}: 111.8%;
      }
    }
    a.read_article {
      margin-#{$ldirection}: 47.1%;
    }
  }
}

.careers-page {
  .careers-nav {
    &.sidebar-page__sidebar {
      display: none;
    }
  }
  .sidebar-page__content {
    &.careers-page__content {
      width: 100%;
    }
  }
}

.page-header {
  nav {
    &.page-navigation {
      clear: both;
    }
  }
}

.section-envy-lustre {
  .mpp.envy-lustre__frame {
    max-width: 1366px;
    .six-product__previous {
      left: 4.6%;
      top: 28%;
    }
    .six-product__next {
      left: 88.5%;
      top: 28%;
    }
  }
}

.hero-block__promo {
  .hero-block__boutique {
    .hero-block__boutique-link {
      background-position: -6px -3276px;
    }
  }
}

.world-of-aerin-creating-a-fragrance-page {
  .woa-blog__content {
    .basic-textarea-v1 {
      .ee-first-letter {
        padding-top: 0px;
      }
    }
  }
}

@media #{$medium-down} {
  .hero-block__headline {
    .header__headline {
      line-height: 60px;
      font-size: 60px;
    }
    .header__headline.headline--small {
      font-size: 5vmin;
      line-height: 4vmin;
    }
  }
  .hero-block-wrapper {
    .multiuse-b-anrampoules-53 {
      .hero-block__container {
        .hero-block__promo {
          #{$rdirection}: 12% !important;
        }
      }
    }
  }
}

@media #{$xlarge-up} {
  .flexslider:hover {
    .flex-prev {
      #{$ldirection}: 830px;
    }
    .flex-next {
      #{$ldirection}: -80px;
    }
  }
  .flexslider {
    .flex-direction-nav {
      .flex-prev,
      .flex-next {
        font-size: 0px;
      }
    }
  }
}

.product_brief__strike-out {
  color: #808080;
  text-decoration: line-through;
}

.product-full__retail-price,
.quickshop__retail-price {
  color: #808080;
  margin-right: 7px;
}

.section-esteestories {
  .ee-carousel-formatter {
    margin-top: 10px;
  }
}

.section-night-experience {
  .page-branding {
    .page-branding__logo {
      background-size: 188px 26px;
    }
  }
}

.double-wear-cushion-stick-page {
  .sumptuous-knockout-anr {
    @include swap_direction(margin, 72px auto 47px auto);
  }
}

.page-product-victoria-beckham-estee-lauder {
  .product__inventory-status-container {
    .button--dark {
      display: none;
    }
  }
}

.page-wrapper {
  .product__inventory-status {
    .product__inv-status-item {
      color: $color-red;
    }
  }
}

.pcl_rtl {
  #pcl_swatch-4-base {
    margin-right: 71.6%;
  }
  #pcl_swatch-4-hover {
    margin-right: 71.6%;
  }
  #pcl_swatch-3-base {
    margin-right: 61.37%;
  }
  #pcl_swatch-3-hover {
    margin-right: 61.37%;
  }
  #pcl_swatch-2-base {
    margin-right: 46.8%;
  }
  #pcl_swatch-2-hover {
    margin-right: 46.8%;
  }
  #pcl_swatch-1-base {
    margin-right: 31.7%;
  }
  #pcl_swatch-1-hover {
    margin-right: 31.7%;
  }
  #pc_love__swatch-overlaidText-3 {
    margin-right: 64%;
  }
  #pc_love__swatch-overlaidText-4 {
    margin-right: 79.3%;
  }
  #pc_love__swatch-overlaidText-2 {
    margin-right: 51.6%;
  }
  #pc_love__swatch-overlaidText-1 {
    margin-right: 34.2%;
  }
  #pc_love__swatch-text1 {
    margin-top: -4.25%;
    margin-right: 71px;
  }
}

#pc_love__swatch-subcopy {
  margin-top: -26.9% !important;
  margin-right: 14px;
}

div#pc_love__gif-text1 {
  margin-right: 913px;
}

#pc_love__gif {
  margin-right: 5.5%;
}

#pc_love__gif-subcopy {
  margin-right: 73px;
}

#pc_love__yourLooksChevronRightBottom,
#pc_love__yourLooksChevronRightTop {
  margin-right: 14%;
}

#pc_love__yourLooksChevronLeftBottom,
#pc_love__yourLooksChevronLeftTop {
  margin-right: 78.89%;
}

.page-wrapper {
  .product__inventory-status {
    .product__inv-status-item {
      span {
        color: $color-red !important;
      }
    }
  }
}

body {
  .hero-block__promo-copy {
    .text-promo__subcopy {
      strong {
        font-size: 23px !important;
        font-weight: normal !important;
      }
    }
    .text-promo__subhead {
      font-weight: normal !important;
    }
  }
}

.victoria-beckham-signup-content-elc-nodeblock {
  .newsletter_terms {
    left: 135px;
  }
  .email-signup__error {
    left: 219px;
    position: relative;
  }
  .basic-textarea-v1 {
    div {
      margin-left: 25px;
      margin-top: 75px !important;
    }
  }
  .signup-tout-wrapper {
    .signup-tout-content {
      .thank_you_text {
        position: relative !important;
        left: 34% !important;
      }
    }
  }
}

.page-branding {
  .trust-mark-elc-nodeblock {
    float: left;
    .trust_mark_header {
      position: relative;
      width: 80px;
      height: 80px;
      .trust_mark_header__link {
        position: absolute;
        width: 70px;
        height: 80px;
        top: -13px;
        background-image: url('/media/images/global/lo3-white_heb.png');
        background-repeat: no-repeat;
        text-indent: -9999px;
      }
    }
  }
}

.page-branding__logo {
  float: left;
  background-size: 187px 26px !important;
  width: 187px !important;
  height: 26px !important;
}

// 2018 Creative refresh
.page-header.alt-color-white {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        top: -16px;
        background-image: url('/media/images/global/trustmark_navy_li.png');
        @media #{$xlarge-up} {
          top: -13px;
        }
      }
    }
  }
  .page-utilities {
    .utility-nav__cart {
      @media #{$medium-up} {
        position: relative;
        right: 25px;
      }
      @media #{$xlarge-up} {
        right: 49px;
      }
    }
    .utility-nav__cart-button {
      border-right: none;
    }
    .utility-nav__account {
      @media #{$medium-up} {
        right: -20px;
      }
      @media #{$xlarge-up} {
        right: -10px;
      }
    }
    &__search-button {
      @media #{$medium-up} {
        left: 62px;
        border-right: 1px solid #ccc;
      }
      @media #{$xlarge-up} {
        padding: 0 18px;
      }
    }
    &__cart-icon {
      background-image: url('/media/images/global/bag-blue.png');
    }
    &__search-icon {
      background-image: url('/media/images/global/search_blue.png');
    }
    &__signin-text {
      color: $color-dark-navy;
    }
  }
  .utility-nav__signin-text {
    color: $color-dark-navy;
  }
  .page-navigation {
    .level-1 {
      color: $color-dark-navy;
    }
  }
  &:not(#{$alt}) {
    .page-navigation__menu {
      @media #{$device-ipad_mini} {
        &-icon {
          background-image: url('/media/export/cms/gnav/menu_icon_navy.png');
        }
        &_text {
          color: $color-dark-navy;
        }
      }
    }
  }
}

.brand-renutriv,
.section-re-nutriv {
  .page-header.alt-color-white:not(#{$alt}) {
    .trust-mark-elc-nodeblock {
      .trust_mark_header {
        .trust_mark_header__link {
          top: -16px;
          background-image: url('/media/images/global/lo3-white_heb.png');
          @media #{$xlarge-up} {
            top: -13px;
          }
        }
      }
    }
    .page-utilities {
      &__cart-icon {
        background-image: url($base-theme-image-path + 'sprites/cart_white.png');
      }
      &__search-icon {
        background-image: url('/media/images/global/search-icon-white.png');
      }
      &__signin-text {
        color: $color-white;
      }
    }
    .utility-nav__signin-text {
      color: $color-white;
    }
  }
  .page-header:not(#{$alt}) {
    .page-navigation__menu {
      @media #{$device-ipad_mini} {
        &-icon {
          background: url('/media/export/cms/gnav/menu_icon_white.png');
        }
        &_text {
          color: $color-white;
        }
      }
    }
  }
}

.section-foundation-finder {
  .page-header.alt-color-white:not(#{$alt}) {
    @media #{$xlarge-up} {
      background: $ff-background-color;
    }
  }
}

.page-header {
  &.is-search {
    &.alt-color-white {
      @media #{$xlarge-up} {
        .device-pc & {
          background-color: $color-white;
        }
      }
    }
  }
  .page-navigation__menu {
    text-align: center;
    ul.menu {
      li {
        text-align: right;
      }
    }
  }
  &:not(#{$alt}) {
    .page-navigation__menu {
      @media #{$device-ipad_mini} {
        &-icon {
          background-image: url('/media/export/cms/gnav/menu_icon_white.png');
        }
        &_text {
          color: $color-white;
        }
      }
    }
  }
}

.gnav-active,
.mobile-gnav-active {
  &.brand-renutriv,
  &.section-re-nutriv {
    .page-wrapper {
      .page-header {
        &#{$alt} {
          .page-branding__logo {
            @media #{$device-ipad_mini} {
              margin-top: 0;
            }
          }
          .utility-nav__account {
            a {
              color: $color-dark-navy;
            }
          }
        }
      }
    }
  }
  #{$alt},
  &.brand-renutriv,
  &.section-re-nutriv,
  &#beauty_feed {
    .trust-mark-elc-nodeblock {
      .trust_mark_header {
        .trust_mark_header__link {
          top: -16px;
          background-image: url('/media/images/global/trustmark_navy_li.png');
          @media #{$xlarge-up} {
            top: -13px;
          }
        }
      }
    }
  }
}

.page-utilities-loyalty-elc-nodeblock {
  float: right;
}

.utility-nav__account {
  .user-loyalty-state {
    display: none;
  }
}

.header-items {
  @media #{$device-ipad_mini} {
    padding: 0 0 15px 0;
  }
  @media #{$xlarge-up} {
    padding: 9px 0 0;
  }
}

.page-navigation__menu {
  @media #{$large-down} {
    ul.menu li {
      margin: 0;
    }
    .menu-ref,
    .menu__item--lvl-3,
    .menu__item--lvl-5 {
      &.menu__item--back {
        &:before {
          background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
        }
      }
    }
    .menu-ref--has-children .menu-ref__title,
    .menu__item--category-expandable {
      &:after {
        background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
      }
    }
  }
  .menu-ref {
    &__title,
    &__title a {
      @media #{$xlarge-up} {
        font-size: 15px;
      }
    }
    .menu__item {
      &--category {
        @media #{$xlarge-up} {
          font-size: 15px;
        }
      }
      &--link {
        @media #{$xlarge-up} {
          line-height: 22px;
        }
      }
    }
  }
  .menu--lvl-2 {
    .menu-ref.menu-ref--has-children > .menu-ref__title,
    .menu__item--category-expandable {
      @media #{$xlarge-up} {
        font-size: 15px;
      }
    }
  }
}

.section-aerin,
.brand-aerin,
.section-re-nutriv,
.brand-renutriv {
  .page-header {
    &#{$alt} {
      .page-navigation__menu {
        a {
          color: $color-dark-navy;
        }
      }
    }
  }
}

.product_brief {
  &__image-container {
    &.is-open {
      .product_brief {
        &__image {
          @media #{$medium-up} {
            height: 422px;
          }
        }
      }
    }
  }
}

.noUi-base {
  @include swap_direction(direction, ltr);
}

.content {
  .foundation-finder {
    &--quiz {
      &__nav-menu {
        padding: 15px;
      }
      &__nav-value {
        margin: 0 15px;
      }
    }
  }
}

.elc-product-quick-view-wrapper {
  text-align: $ldirection;
  .elc-product-quick-view-content {
    .elc-product-shades-container {
      overflow-x: hidden;
    }
    .elc-product-quick-view-details-wrapper {
      width: 50%;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 64px;
      .elc-product-price-row-wrapper {
        margin-#{$ldirection}: 0;
      }
    }
    .elc-product-badge-image-wrapper {
      width: 50%;
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: 64px;
      .elc-carousel-products {
        .elc-slider-arrow-wrapper {
          &.slick-prev {
            #{$ldirection}: auto;
            #{$rdirection}: 0;
          }
          &.slick-next {
            #{$ldirection}: 0;
            #{$rdirection}: auto;
            margin-top: 0;
          }
        }
      }
    }
  }
}

.sd-product-grid {
  .elc-grid-item-product {
    .elc-product-brief-wrapper {
      .elc-product-detail-wrapper {
        .elc-product-brief-detail-wrapper {
          .elc-product-item-wrapper {
            text-align: $ldirection;
          }
          .elc-product-shade-picker-wrapper {
            .elc-single-shade-wrapper {
              p {
                margin-#{$ldirection}: 25px;
              }
            }
          }
          .elc-product-price-wrapper {
            margin-#{$ldirection}: 0;
            .elc-product-tax-included-label-wrapper {
              float: $ldirection;
            }
            .elc-product-price-row-wrapper {
              margin-#{$ldirection}: 0;
              .elc-product-original-price {
                padding-#{$ldirection}: 0;
              }
            }
          }
        }
      }
    }
    .elc-product-brief {
      .elc-product-price-row-wrapper {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.skin-finder-page-page {
  .quiz-page-formatter {
    &__body {
      .skin-finder-results {
        &__content {
          .grid {
            &--mpp {
              &__carousel {
                .carousel-controls {
                  .slick-prev {
                    #{$rdirection}: 0;
                    #{$ldirection}: auto;
                  }
                  .slick-next {
                    #{$ldirection}: 0;
                    #{$rdirection}: auto;
                  }
                }
              }
              &__items {
                .product-brief {
                  &__image-content {
                    .qs_link {
                      #{$ldirection}: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-formatter {
      &[data-form-style='show_single'] {
        .form-formatter {
          &__item[data-field-name='intro'] {
            .mantle-form-options-field {
              #{$rdirection}: 50%;
              #{$ldirection}: auto;
            }
          }
        }
      }
      &[data-current-step='1'] {
        .mantle-form-options-field {
          &__footer-cta {
            a {
              #{$ldirection}: 41%;
              transform: none;
            }
          }
        }
      }
      &__back-btn {
        #{$rdirection}: 57%;
        #{$ldirection}: auto;
      }
    }
    &.show-quiz {
      .mantle-form-options-field {
        #{$rdirection}: 50%;
        #{$ldirection}: auto;
        &__footer-cta {
          a {
            #{$ldirection}: auto;
            #{$rdirection}: 42%;
          }
        }
      }
    }
  }
}
