.enhanced_delivery_page {
  &#shipping {
    .last_name_container {
      padding-#{$ldirection}: 0 !important;
    }
    #delivery-options {
      .shipform_fields {
        .ship-method-group-label {
          margin-bottom: 20px;
          .delivery_option {
            border: 1px solid $color-navy;
            display: inline-block;
            height: 94px;
            margin-#{$rdirection}: 12px;
            max-width: 269px;
            opacity: 0.4;
            text-align: center;
            width: 100%;
            &.active {
              opacity: 1;
            }
            label {
              color: $color-navy;
              font-size: 14px;
              font-weight: normal;
              line-height: 4.2;
              margin: 33px 0 0;
              padding: 0;
              &::before,
              &::after {
                display: none;
              }
            }
            &.home_delivery_option,
            &.click_and_collect_option {
              display: inline-block;
              position: relative;
              text-align: center;
              width: 100%;
              &::before {
                #{$ldirection}: 0;
                background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center /
                  contain;
                content: '';
                height: 24px;
                position: absolute;
                top: 20px;
                width: 100%;
              }
            }
            &.click_and_collect_option {
              margin-#{$ldirection}: 12px;
              margin-#{$rdirection}: 0;
              &::before {
                background-image: url('/media/images/checkout/pickpoint-icon.png');
              }
            }
          }
        }
      }
    }
  }
  #gift-options {
    .title-icon {
      padding-#{$ldirection}: 20px;
    }
  }
  #gift-options,
  #newsletter-container {
    .giftwrap-panel__title,
    .checkout__panel-title {
      font-family: $assistant, $optimalight;
    }
    .title-icon {
      &::after {
        #{$ldirection}: 14px;
      }
    }
  }
  .page-header {
    background: $color-white;
  }
  #review-address-info {
    .transaction-details {
      .transaction-item {
        width: 100% !important;
      }
    }
  }
  .pg_wrapper {
    .payment-panel {
      .payment-properties {
        p {
          display: none;
        }
        h2 {
          font-size: 21px;
          text-align: center;
        }
      }
    }
    .checkout_container {
      .checkout__content {
        .checkout-progress-bar {
          margin: 38px 12px;
          &__list {
            display: flex;
            &-item {
              &::after {
                #{$rdirection}: 50%;
              }
            }
          }
        }
        .checkout-buttons-container {
          margin-top: 58px;
        }
        #checkout_shipping {
          .checkout__panel-title {
            border: 0;
            &.federal-title {
              margin: 0;
              padding-bottom: 10px;
            }
          }
          #create_account_form_container {
            .form-item {
              .field {
                @include swap_direction(padding, 17px 20px 13px 15px);
                border: 1px solid $color-navy;
                box-sizing: border-box;
                height: 54px;
                max-width: 562px;
                &:read-only {
                  background: $color-azure-transparent;
                }
              }
            }
          }
          #address-section-container {
            .edit-shipping-address {
              .country_container {
                padding-top: 15px;
              }
              .delivery_instructions_container {
                textarea.field {
                  height: 94px;
                  & + label {
                    line-height: 5.2;
                  }
                }
              }
            }
            .billing-panel {
              .address_controls {
                .edit-address {
                  top: 45px;
                }
              }
            }
            .billing-display {
              .country_container {
                background: $color-azure-transparent;
                border: 1px solid $color-navy;
                height: 54px;
                padding: 14px;
              }
            }
            #billing_address_form_container {
              .country_container {
                background: $color-azure-transparent;
                padding-#{$ldirection}: 17px;
                &::before {
                  content: none;
                }
              }
            }
            .address_form_container {
              .form-item {
                label {
                  margin-#{$rdirection}: 90%;
                }
              }
              .delivery_instructions_container {
                text-align: $ldirection;
                label {
                  &::before {
                    color: $color-light-gray;
                  }
                }
              }
              .country_container {
                padding-top: 15px;
                text-align: $ldirection;
              }
            }
          }
        }
      }
      .checkout__sidebar {
        .payment-panel {
          #review-address-info {
            .transaction-details {
              .transaction-item {
                padding-#{$ldirection}: 13px;
              }
            }
          }
        }
      }
    }
  }
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        #content {
          &.checkout__content {
            #gift-options,
            #newsletter-container {
              .giftwrap-panel__title,
              .checkout__panel-title {
                font-family: $assistant, $optimalight;
              }
            }
          }
        }
        .checkout__sidebar {
          #checkout-sidebar {
            .order-summary-panel {
              #order-status {
                .order-summary-panel__title {
                  font-family: $assistant, $optimalight;
                }
                table {
                  .total {
                    td.total-label,
                    td.price {
                      font-family: $assistant, $optimalight;
                    }
                  }
                }
              }
            }
            #offer_header {
              font-family: $assistant, $optimalight;
            }
            .payment-panel {
              .gift_container,
              .promocode-container {
                display: none;
              }
              #review-address-info {
                .transaction-item {
                  &.offer-code-message-section {
                    display: block;
                    .giftwrap-content {
                      display: none;
                    }
                  }
                  .checkout__subtitle {
                    font-family: $assistant, $optimalight;
                  }
                }
              }
            }
            .order-summary-panel {
              .title-icon {
                #{$ldirection}: 5px;
                position: relative;
                &::after {
                  #{$ldirection}: 13px;
                }
              }
            }
            .transaction-item {
              .title-icon {
                #{$rdirection}: 11px;
              }
            }
            #offer_header,
            .transaction-item {
              .title-icon {
                position: relative;
                &::after {
                  #{$rdirection}: 2px;
                }
              }
            }
            #offer-code-panel {
              .title-icon {
                position: relative;
              }
            }
          }
        }
        .checkout__content {
          #address-section-container {
            .shipping-address-form-container {
              .first_name_container {
                input[type='text'].adaptive-label-mode {
                  & + label::before {
                    transform: translateY(-33px) scale(0.8, 0.8);
                  }
                }
              }
            }
            #shipping-panel.billing-panel {
              .address_controls {
                .edit-address {
                  margin-top: 50px;
                  top: 0;
                }
              }
            }
          }
          .payment-panel {
            #payment-type-cc {
              border-bottom: 0;
            }
          }
          &#content {
            #gift-options {
              .gift-options__title {
                .title-icon {
                  width: 54px;
                }
              }
            }
          }
        }
      }
    }
  }
  .shipping-address-form-container,
  .billing-address-form-container {
    .delivery_instructions_container {
      textarea.field {
        min-height: 94px;
        & + label {
          line-height: 5.2;
        }
      }
    }
  }
}
