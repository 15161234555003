.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-head {
          float: right;
        }
      }
    }
  }
}
