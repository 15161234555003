input[type='radio'].error ~ label {
  color: $color-red;
}

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: $rdirection;
}

.cleft {
  clear: $ldirection;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

#cboxLoadedContent {
  .sign-in-component {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .email_popover {
    .error {
      font-size: 11px;
    }
  }
}

.page-footer {
  .responsive-footer {
    ul {
      &.menu {
        text-align: $ldirection;
        li {
          @include swap_direction(margin, 0);
          &.collapsed,
          &.expanded,
          &.leaf {
            @include swap_direction(padding, 0);
          }
        }
      }
    }
    @media #{$device-ipad_only} {
      @media #{$portrait} {
        .footer-header {
          background-size: auto auto;
          background: url('/media/images/global/sprites-s38a9885fce.png') 0 -3547px no-repeat;
          background-color: $color-navy;
        }
        .is_open {
          .footer-header {
            background-size: auto auto;
            background: url('/media/images/global/sprites-s38a9885fce.png') 100% -3445px no-repeat;
            background-color: $color-navy;
          }
        }
      }
    }
    .country-chooser__selector {
      background-position: 250% -1662px;
    }
    .country-chooser__selector:hover,
    .country-chooser__selector.country_chooser-hover,
    .country-chooser__selector.active {
      background-position: 250% -1725px;
    }
    .footer-signup-email {
      .email_signup__terms {
        clear: both;
        padding-top: 18px;
        .label {
          line-height: 14px;
          font-size: 11px;
        }
      }
    }
  }
}

.page-header {
  .page-navigation {
    ul {
      &.menu {
        text-align: center;
        li {
          @include swap_direction(margin, 0);
          &.collapsed,
          &.expanded,
          &.leaf {
            @include swap_direction(padding, 0);
          }
          &.menu-item-container {
            @include swap_direction(padding, 0 20px);
          }
        }
      }
    }
    &__hotswap {
      ul {
        &.menu {
          li {
            text-align: $ldirection;
          }
        }
      }
    }
    .level-1 {
      background-position: 0 -5819px;
    }
    .js-active {
      .level-1 {
        background-position: 100% -5869px;
      }
    }
    .responsive-carousel-nav {
      .flex-direction-nav {
        .flex-prev {
          width: 60px;
        }
      }
      .flexslider {
        direction: ltr;
      }
      .flexslider:hover {
        .flex-next {
          #{$ldirection}: -50px;
          #{$rdirection}: auto;
        }
        .flex-prev {
          #{$rdirection}: -50px;
          #{$ldirection}: auto;
        }
      }
    }
  }
}

#toolbar {
  #togglemenus {
    ul {
      .mobile-preview,
      .akamai {
        div {
          position: static;
        }
      }
      .mobile-preview {
        ul {
          left: -160px;
        }
      }
    }
  }
}

.ee-mb-tile__content {
  img {
    float: $rdirection;
    @media #{$brand-story-one-col} {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
      float: none;
    }
  }
}

@media #{$device-ipad_only} {
  @media #{$portrait} {
    .main-content {
      overflow: hidden;
      .mpp {
        &__product-grid {
          .mpp__product:nth-child(3n + 1) {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
}

.selectBox-dropdown-menu {
  li {
    a {
      .filter-light {
        background: #eec8ab;
      }
    }
  }
}

.slick-slider {
  .slick-slide {
    float: $ldirection;
  }
}

#lpChat {
  .lp_question {
    .lp_radio_button {
      label {
        &::before,
        &::after {
          content: none;
        }
      }
    }
  }
}

select {
  background-position: $rdirection 10px center !important;
}

.page-wrapper {
  .product-full__description-section {
    .BVRRPrimarySummary {
      display: inline-block;
      .BVRRRatingSummaryLinkRead {
        padding-#{$ldirection}: 0;
        margin-#{$ldirection}: 0;
      }
    }
  }
}

#colorbox.welcome15-overlay {
  .email_popover {
    .email_signup__terms {
      padding-top: 14px;
    }
    &__social_icons {
      padding-top: 21px;
    }
  }
}

.section-night-experience {
  .anr-night-experience__sticky-nav {
    #anr-night-experience__sticky-navLinks {
      margin-#{$rdirection}: 0;
      margin-#{$ldirection}: -80%;
      padding: 5.3% 9% 6.8% 0;
    }
  }
}

#anr-night-experience__carousel2-text7 {
  margin-#{$rdirection}: 0;
  margin-#{$ldirection}: 28%;
}

#anr-night-experience__carousel2-text11 {
  margin-#{$rdirection}: 0;
  margin-#{$ldirection}: 14%;
}

#anr-night-experience__carousel2-text15 {
  margin-#{$rdirection}: 0;
  margin-#{$ldirection}: 14%;
}

#anr-night-experience__carousel2-text23 {
  margin-#{$ldirection}: 14%;
  margin-#{$rdirection}: 0%;
}

#anr-night-experience__carousel2-stars3 {
  margin-#{$rdirection}: 11.8%;
  #{$ldirection}: 25%;
}

#anr-night-experience__carousel2-stars6,
#anr-night-experience__carousel2-stars4 {
  #{$ldirection}: 25%;
  margin-#{$rdirection}: 0;
}

#anr-night-experience__carousel2-text5,
#anr-night-experience__carousel2-text6 {
  margin-#{$rdirection}: 0;
  margin-#{$ldirection}: 10%;
}

#anr-night-experience__carousel2-stars1,
#anr-night-experience__anr-quick-shop-module7-6 {
  margin-#{$rdirection}: 0;
  #{$ldirection}: 25%;
}

#anr-night-experience__carousel2-text31 {
  margin-#{$ldirection}: 13%;
  margin-#{$rdirection}: 0;
}

#anr-night-experience__anr-quick-shop-module7-1 {
  #{$ldirection}: 25%;
}

#anr-night-experience__carousel2-stars2 {
  #{$ldirection}: 26%;
  margin-#{$rdirection}: 0;
}

#anr-night-experience__carousel2-text39 {
  margin-#{$rdirection}: 0;
  margin-#{$ldirection}: 10%;
}

#anr-night-experience__carousel2-text48 {
  margin-#{$ldirection}: 12%;
  margin-#{$rdirection}: 0;
}

#anr-night-experience__carousel2-text47 {
  margin-#{$ldirection}: 13%;
  margin-#{$rdirection}: 0;
}

.section-night-experience {
  #anr-night-experience__carousel {
    #anr-night-experience__carousel-3-text2,
    #anr-night-experience__carousel-2-text4,
    div#anr-night-experience__carousel3-video-link {
      float: #{$rdirection};
    }
    div#anr-night-experience__carousel3-video-link {
      margin-#{$rdirection}: 6.6%;
    }
    #anr-night-experience__carousel2-video-link {
      margin-#{$rdirection}: 10%;
      float: #{$rdirection};
      margin-top: 3%;
    }
    #anr-night-experience__carousel-2-text6 {
      margin-#{$rdirection}: 61.7%;
    }
  }
}

.anr-night-experience__carousel1-chevrons-Left {
  margin-#{$ldirection}: 2%;
  padding-#{$rdirection}: 0;
  left: 0;
}

.anr-night-experience__carousel1-chevrons-Right {
  margin-#{$ldirection}: 2%;
  padding-#{$rdirection}: 0;
}

.page-navigation {
  .level-1 {
    line-height: 16px;
    vertical-align: top;
  }
}

@media only screen and (min-width: 737px) {
  .page-header {
    padding: 9px 0 0;
    min-height: 85px;
  }
}

#rn_container {
  #renutriv_section-5 {
    img.title {
      height: 17.1669% !important;
      top: -17.1669% !important;
    }
  }
}

.anr-night-experience__carousel.hero {
  float: #{$ldirection};
}

#toolbar {
  #togglemenus {
    ul .has-sub div {
      position: static;
    }
  }
}

.email_popover {
  &__content {
    .email_signup__terms {
      label {
        .label::after {
          border: 1px solid #cccccc;
          background-image: url('/media/images/pro_active_popup/transparent_close_x.png');
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}

.product__inventory-status-container {
  .js-inv-status-2 {
    span {
      color: blue !important;
    }
  }
}

.special-offer-promo-text-wrapper {
  .special-offer-button-popup {
    font-family: $assistant !important;
  }
}

#facechart-container {
  .facechart-intro {
    .column {
      .desktop {
        h2 {
          font-family: $assistant, $optimaregular;
        }
      }
      blockquote {
        font-family: $assistant, $optimaregular;
        p,
        span {
          font-family: $assistant, $optimaregular;
        }
      }
    }
  }
}

.appt-book {
  #confirmation {
    .confirmation-container {
      .artist {
        display: none;
      }
    }
  }
  .services-container {
    margin-bottom: 0 !important;
  }
  .service-select {
    .service {
      .service-details {
        h3.service-title {
          line-height: normal !important;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .appointment-select {
        .calendar-head {
          .cal-head-content {
            .cal-controls.previous {
              background-image: url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png');
            }
            .cal-controls.next {
              background-image: url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png');
            }
          }
        }
      }
      .book-appt-container {
        input {
          color: black !important;
        }
        .mobile-phone-fieldset {
          float: left;
          input.appt-book-mobile-prefix {
            width: 20%;
            border-right: none;
          }
          input.appt-book-mobile {
            width: 80%;
            float: left;
            margin-bottom: 15px;
          }
        }
        .registration__privacy-policy-list {
          margin-top: 20px;
        }
      }
      .confirm-container {
        max-width: 440px;
      }
    }
  }
}

#appt-book-datepicker-overlay {
  #appt-book-datepicker-calendar-container {
    .pika-lendar {
      float: right;
      .pika-prev {
        float: right;
        background-image: url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png');
      }
      .pika-next {
        float: left;
        background-image: url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png');
      }
    }
  }
}

.ie .appt-book-overlay.appt-book-datepicker-overlay {
  .overlay-content-container {
    .pika-label select {
      width: 90px !important;
    }
  }
}

.mpp {
  .product_brief {
    &__headers {
      height: auto;
    }
    &__panel {
      display: block;
    }
  }
}

h3 {
  &.product-brief__header,
  &.product_brief__header,
  &.product-full__title {
    direction: ltr;
  }
}

h4 {
  &.search-product__header {
    direction: ltr;
  }
}

.cart-confirm {
  &__header {
    direction: ltr;
  }
}
